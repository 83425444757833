import React from 'react';
import Loader from '../assets/loading-new.gif';
import { dateFormattingWithMnSec, dateFormatting } from './functions';
import HandleImageDisplay from '../components/HandleImageDisplay';
import LogoRp from '../assets/square_rp_logo.png';
const { REACT_APP_API_RP_KEY } = process.env;

export const radioPriority = [
	{ name: 'Radios prioritaires des Associés', id: 1 },
	{ name: 'Radio non-prioritaire des Associés', id: 2 },
	{ name: 'Radio adhérente gold', id: 3 },
	{ name: 'Autres radios', id: 4 },
	{ name: 'Toutes les webradios', id: 5 },
	{ name: 'Radios contrat Basic', id: 6 },
];

export const validDataModel = [
	{ name: 'nom_moyen', type: 'string', maxLength: 16, value: null },
	{ name: 'nom_long', type: 'string', maxLength: null, value: null },
	{ name: 'description_courte', type: 'string', maxLength: 250, value: null },
	{ name: 'description_longue', type: 'string', maxLength: null, value: null },
	{ name: 'image_1400', type: 'string', maxLength: 200, value: null },
	{ name: 'heure_debut', type: 'string', maxLength: 5, value: null },
	{ name: 'heure_fin', type: 'string', maxLength: 5, value: null },
	{ name: 'lundi', type: 'string', maxLength: null, value: ['O', 'N', ''] },
	{ name: 'mardi', type: 'string', maxLength: null, value: ['O', 'N', ''] },
	{ name: 'mercredi', type: 'string', maxLength: null, value: ['O', 'N', ''] },
	{ name: 'jeudi', type: 'string', maxLength: null, value: ['O', 'N', ''] },
	{ name: 'vendredi', type: 'string', maxLength: null, value: ['O', 'N', ''] },
	{ name: 'samedi', type: 'string', maxLength: null, value: ['O', 'N', ''] },
	{ name: 'dimanche', type: 'string', maxLength: null, value: ['O', 'N', ''] },
	{ name: 'feed_podcast', type: 'string', maxLength: 200, value: null },
	{ name: 'cat_1', type: 'number', maxLength: null, value: null },
	{ name: 'cat_2', type: 'number', maxLength: null, value: null },
];

export const modalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '50vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		fontSize: '2vh',
		border: '2px solid #6e8510',
		zIndex: '99',
	},
};

export const innerModalStyles = {
	content: {
		height: '280px',
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		width: '50vh',
		display: 'flex',
		flexDirection: 'column',
		fontSize: '2vh',
		border: '2px solid #6e8510',
		overflowY: 'scroll',
		zIndex: '99',
	},
};

export const UsersColumnsInfos = [
	{
		Header: 'Email',
		accessor: 'email',
	},
	{
		Header: 'Administrateur',
		accessor: (b) => (b.isAdmin === 1 ? 'Oui' : 'Non'),
	},
	{
		Header: 'Analytique',
		accessor: (b) => (b.isAnalytics === 1 ? 'Oui' : 'Non'),
	},
	{
		Header: 'Copil',
		accessor: (b) => (b.isCopil === 1 ? 'Oui' : 'Non'),
	},
	{
		Header: 'groupe_id',
		accessor: 'groupe_id',
	},
	{
		Header: 'groupe_id_2',
		accessor: 'groupe_id_2',
	},
	{
		Header: 'Statistique Uniquement',
		accessor: (b) => (b.onlyStats === 1 ? 'Oui' : 'Non'),
	},
	{
		Header: 'Date de Creation',
		accessor: (b) => {
			const date = new Date(b.createdAt);
			return (
				date.getDate() +
				1 +
				'-' +
				date.getMonth() +
				'-' +
				date.getFullYear() +
				' ' +
				date.getHours() +
				'h:' +
				date.getMinutes() +
				'mn'
			);
		},
	},

	{
		Header: 'Date de Mise à jour',
		accessor: (b) => {
			const date = new Date(b.updatedAt);
			return (
				date.getDate() +
				1 +
				'-' +
				date.getMonth() +
				'-' +
				date.getFullYear() +
				' ' +
				date.getHours() +
				'h:' +
				date.getMinutes() +
				'mn'
			);
		},
	},
	{
		Header: 'Actif',
		accessor: (b) => (b.isAllowed === 1 ? 'Oui' : 'Non'),
	},
	{
		id: 'ActionsID',
		Header: 'Actions',
	},
];

export const RadiosColumnsInfos = [
	{
		Header: 'rpid',
		accessor: (b) => b.rpID,
		className: 'radioIdColumn',
	},

	{
		id: 'LogoID',
		Header: 'Logo',

		Cell: (tableProps) => (
			<>
				{tableProps.row.original.logos['128_128'] ? (
					<HandleImageDisplay
						imageUrl={tableProps.row.original.logos['128_128']}
						imageName={tableProps.row.original.name}
						width={100}
						height={100}
					/>
				) : (
					<img src={LogoRp} border="" width="128" alt="loader" />
				)}
			</>
		),

		className: 'radioLogoColumn',
	},

	{
		Header: 'Name',
		accessor: (b) => b.nom,
		className: 'radioNameColumn',
	},
	{
		Header: 'Type',
		accessor: (b) => (b.type === '1' ? 'BroadCast' : b.type === '2' ? 'Webradio' : 'Logo Only'),
		className: 'radioTypeColumn',
	},
	{
		Header: 'Slogan',
		accessor: (b) => b.description_courte,
		className: 'radioSloganColumn',
	},
	{
		id: 'ActionsID',
		Header: 'Actions',
		className: 'radioActionsColumn',
	},
];
const weekDays = [
	{ dataName: 'lundi', name: 'lun' },
	{ dataName: 'mardi', name: 'mar' },
	{ dataName: 'mercredi', name: 'mer' },
	{ dataName: 'jeudi', name: 'jeu' },
	{ dataName: 'vendredi', name: 'ven' },
	{ dataName: 'samedi', name: 'sam' },
	{ dataName: 'dimanche', name: 'dim' },
];
export const RadioProgramsColumnsInfos = [
	{
		Header: 'ID',
		accessor: 'id',
	},

	{
		Header: 'Logo',
		Cell: (tableProps) => (
			<>
				{tableProps.row.original.image_1400 ? (
					<HandleImageDisplay
						imageUrl={tableProps.row.original.image_1400}
						imageName={'player'}
						width={150}
						height={150}
					/>
				) : (
					<img src={LogoRp} border="" width="150" alt="loader" />
				)}
			</>
		),
		className: 'radioLogoColumn',
	},
	{
		Header: 'Nom',
		Cell: (tableProps) => (
			<div className="d-flex flex-column align-items-center">
				<div className="text-start w-50">{tableProps.row.original.nom_long}</div>
				<div className="d-flex w-50 text-start">
					{weekDays.map((day, index) =>
						tableProps.row.original[day.dataName] === 'O' ? (
							<div key={index}>
								<span>{day.name}</span>
								<span>&nbsp;</span>
							</div>
						) : null
					)}
				</div>
			</div>
		),
	},
	{
		Header: 'Heure début',
		accessor: 'heure_debut',
	},
	{
		Header: 'Heure fin',
		accessor: 'heure_fin',
	},
	{
		id: 'ActionsID',
		Header: 'Actions',
		className: 'radioActionsColumn',
	},
];

export const RadiosPendingColumnsInfos = [
	{
		Header: 'rpid - Name',
		accessor: (b) => b.rpID + ' - ' + b.nom,
		className: 'pendingIdNameColumn',
	},
	{
		id: 'ModifID',
		Header: 'Modifications proposées',
	},

	{
		id: 'ActionsID',
		Header: 'Actions',
		className: 'radioActionsColumn',
	},
];

export const ArticlesColumnsInfos = [
	{
		Header: 'Titre',
		id: 'ArticleTitle',
		accessor: 'title',
		className: 'articleColumnTitle',
	},
	{
		Header: 'Contenu',
		id: 'ArticleContent',
		accessor: (b) => b.content,
		Cell: (tableProps) => (
			<div className="ArticleContent" dangerouslySetInnerHTML={{ __html: tableProps.row.original.content }} />
		),
		className: 'articleColumnContent',
	},

	{
		Header: 'Image',
		Cell: (tableProps) => <img src={tableProps.row.original.image} width={100} alt="illustration" />,
		className: 'articleColumnImage',
	},
	{
		Header: 'Statut',
		accessor: (b) => (b.state > 1 ? 'Validé' : b.state === 1 ? 'Refusé' : 'En attente'),
		className: 'articleColumnState',
	},

	{
		id: 'articleId',
		Header: 'id',
		accessor: (b) => b.id,
		className: 'articleColumnId',
	},
	{
		Header: 'Date',
		id: 'ArticleDate',
		accessor: (b) => b.date.substring(0, 10),
		className: 'articleColumnDate',
	},
	{
		id: 'ActionsID',
		Header: 'Actions',
		accessor: 'id',
		className: 'articleColumnActions',
		disableGlobalFilter: 'true',
	},
];

export const PodcastsColumnsInfos = [
	{
		Header: 'ID',
		accessor: 'id',
	},

	{
		Header: 'Cover',
		Cell: (tableProps) => (
			<>
				{tableProps.row.original.image ? (
					<img src={tableProps.row.original.image} width={100} alt="Player" />
				) : (
					<img src={Loader} border="" width="100" alt="loader" />
				)}
			</>
		),
		className: 'radioLogoColumn',
	},
	{
		Header: 'Nom',
		accessor: 'nom',
	},

	{
		Header: 'Feed',
		Cell: (tableProps) => (
			<div className="text-start">
				<a href={tableProps.row.original.feed} target="blank">
					{tableProps.row.original.feed}
				</a>

				{tableProps.row.original.permanent === 'O' && tableProps.row.original.actif === 'O' ? (
					<div className="mt-3 text-success">
						Dernier épisode envoyé le {dateFormattingWithMnSec(tableProps.row.original.last_episode)} mais
						signalé comme permanent.
					</div>
				) : tableProps.row.original.permanent === 'O' && tableProps.row.original.actif === 'N' ? (
					<div className="mt-3 text-danger">
						Attention : dernier épisode envoyé le{' '}
						{dateFormattingWithMnSec(tableProps.row.original.last_episode)}.
					</div>
				) : null}
			</div>
		),
	},
	{
		id: 'ActionsID',
		Header: 'Actions',
		className: 'radioActionsColumn',
	},
];

export const PodcastEpisodesColumnsInfos = [
	{
		Header: 'ID',
		accessor: 'id',
		className: 'episodeIdColumn',
	},

	{
		Header: 'Nom',
		accessor: 'longName',
		className: 'episodeNameColumn',
	},
	{
		Header: 'Date de publication',
		accessor: (e) => dateFormattingWithMnSec(e.start_time),
		className: 'episodeDateColumn',
	},
	{
		Header: 'Fichier audio',
		Cell: (tableProps) => (
			<div className="my-3 d-flex flex-column align-items-start">
				<div>
					<audio controls>
						<source src={tableProps.row.original.audio_url} type="audio/mpeg" />
					</audio>
				</div>
				<div className="text-start">
					<a href={tableProps.row.original.audio_url} target="blank">
						{tableProps.row.original.audio_url}
					</a>
				</div>
			</div>
		),
	},
	{
		id: 'ActionsID',
		Header: 'Actions',
		className: 'episodeActionsColumn',
	},
];

export const OnboardingsColumnsInfos = [
	{
		Header: 'ID',
		accessor: 'id',
		className: 'onboardingIdColumn',
	},
	{
		Header: 'Cover',
		Cell: (tableProps) => (
			<>
				{tableProps.row.original.image ? (
					<img src={tableProps.row.original.image} width={200} alt="cover" />
				) : (
					<img src={Loader} border="" width="200" alt="loader" />
				)}
			</>
		),
		className: 'onboardingCoverColumn',
	},
	{
		Header: 'Titre',
		Cell: (tableProps) => (
			<div className="d-flex flex-column">
				<div className="fw-bold">id: {tableProps.row.original.id_series}</div>
				<div className="fw-bold my-2">{tableProps.row.original.titre}</div>
				<div>
					(
					<a
						href={`https://api.fr.radioplayer.org/metadata/api/v2/episodes?seriesId=${tableProps.row.original.series_id}&rpId=${tableProps.row.original.rpID}&apikey=${REACT_APP_API_RP_KEY}`}
						target="blank"
					>
						lien ici
					</a>
					)
				</div>
			</div>
		),
		className: 'onboardingTitleColumn',
	},
	{
		Header: 'Date de validité',
		Cell: (tableProps) => (
			<div>
				{dateFormatting(tableProps.row.original.date_debut)} -{' '}
				{dateFormatting(tableProps.row.original.date_fin)}
			</div>
		),
		className: 'onboardingValidDateColumn',
	},
	{
		id: 'ActionsID',
		Header: 'Actions',
		className: 'onboardingActionsColumn',
	},
];
export const LocalsColumnsInfos = [
	{
		Header: 'rpID',
		accessor: (b) => b.rpID,
		className: 'localsIdColumn',
	},

	{
		Header: 'Nom',
		accessor: (b) => b.nom,
		className: 'localsNameColumn',
	},
	{
		Header: 'Geofootprint',
		accessor: (b) => (b.geofootprint !== '' ? 'renseigné' : 'non-définie'),
		className: 'localsGeofootprintColumn',
	},
	{
		id: 'ActionsID',
		Header: 'Actions',
		className: 'localsActionsColumn',
	},
];
//tables display
export const globalTableStyle = { border: 'solid 2px #EFEFEF', marginBottom: '2vh' };
export const globalThStyle = {
	background: '#F8F8F8',
	borderBottom: 'solid 2px #EFEFEF',
	color: '#40403E',
	cursor: 'pointer',
};
export const globalTdStyle = {
	padding: '10px',
	borderBottom: 'solid 2px #EFEFEF',
	background: '#EFFFf',
};

export const logosInfos = [
	{ name: '32_32', size: '32x32', width: '32', height: '32' },
	{ name: '48_48', size: '48x48', width: '48', height: '48' },
	{ name: '60_60', size: '60x60', width: '60', height: '60' },
	{ name: '74_41', size: '74x41', width: '74', height: '41' },
	{ name: '86_48', size: '86x48', width: '86', height: '48' },
	{ name: '110_110', size: '110x110', width: '110', height: '110' },
	{ name: '112_32', size: '112x32', width: '112', height: '32' },
	{ name: '128_128', size: '128x128', width: '128', height: '128' },
	{ name: '160_90', size: '160x90', width: '150', height: '84.38' },
	{ name: '256_256', size: '256x256', width: '150', height: '150' },
	{ name: '288_162', size: '288x162', width: '150', height: '84.38' },
	{ name: '320_240', size: '320x240', width: '150', height: '112.5' },
	{ name: '600_600', size: '600x600', width: '150', height: '150' },
	{ name: '1024_768', size: '1024x768', width: '150', height: '112.5' },
	{ name: '1400_1400', size: '1400x1400', width: '150', height: '150' },
	{ name: '1920_1080', size: '1920x1080', width: '150', height: '84.38' },
];
export const radiosInfos = [
	{ name: 'updatedAt', title: 'Dernière mise à jour' },
	{ name: 'type', title: 'Type' },
	{ name: 'nom', title: 'Nom' },
	{ name: 'nom_moyen', title: 'Nom moyen' },
	{ name: 'nom_court', title: 'Nom court' },
	{ name: 'description', title: 'Description' },
	{ name: 'description_courte', title: 'Description courte' },
	{ name: 'facebook', title: 'Facebook' },
	{ name: 'twitter', title: 'Twitter' },
	{ name: 'instagram', title: 'Instagram' },
	{ name: 'url_site', title: 'Url du site' },
	{ name: 'cat_1', title: 'Catégorie 1' },
	{ name: 'cat_2', title: 'Catégorie 2' },
	{ name: 'cat_3', title: 'Catégorie 3' },
	{ name: 'cat_4', title: 'Catégorie 4' },
	{ name: 'cat_5', title: 'Catégorie 5' },
	{ name: 'geofootprint', title: 'Geofootprint' },
];

export const streamsInfos = [
	{ name: 'type', title: 'Type' },
	{ name: 'url', title: 'URL' },
	{ name: 'mime', title: 'Mime' },
	{ name: 'bitrate', title: 'Bitrate' },
];
