import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import {
	selectToken,
	selectRadios,
	selectRadiosErrorMessage,
	selectRadiosMessage,
	selectCategories,
	selectRadiosErrorMessage400,
	selectRadiosServerErrorMessage,
} from '../../../selectors';
import { setErrorMessage, setErrorMessage400, setErrorServerMessage } from '../../../features/radiosSlice';
import { getCategories } from '../../../services/Categories';
import { getRadios } from '../../../services/Radios';
import { createRadio } from '../../../services/Radios';
import InsideMenu from '../../../components/InsideMenu';
import Geofootprint from '../../../components/Geofootprint';
import RadioTypeInputs from '../children/RadioTypeInputs';
import GeneralsInfosInputs from '../children/GeneralsInfosInputs';
import ImgInputs from '../children/ImgInputs';
import UrlInputs from '../children/UrlInputs';
import RpidInput from '../children/RpidInput';
import SocialInputs from '../children/SocialInputs';
import StreamsInputs from '../../../components/StreamsInputs';
import CategoriesInputs from '../children/CategoriesInputs';
import OnboardingPriorityInputs from '../children/OnboardingPriorityInputs';
import DuplicationsInputs from '../children/DuplicationsInputs';
import MotherAndGroupIDInputs from '../children/MotherAndGroupIDInputs';
import CommandAndOutputInputs from '../children/CommandAndOutputInputs';
import VocalSynForm from '../children/VocalSynForm';
import GoToTop from '../../../components/GoToTop';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';

const { REACT_APP_API_URL } = process.env;

function RadioCreate({ type }) {
	const isCreation = true;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [newStream, setNewStream] = useState({ defaut: 'N', url: '', mime: '', bitrate: '' });
	const [newGeofootprint, setNewGeofootprint] = useState('');
	const [shape, setShape] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [radioGroup, setRadioGroup] = useState([]);
	const [sortedRadios, setSortedRadios] = useState([]);
	const [radios, setRadios] = useState([]);
	const [formInputs, setFormInputs] = useState({
		radio: {
			rpid: 0,
			type: '',
			nom: '',
			nom_court: '',
			nom_moyen: '',
			description_courte: '',
			description: '',
			cat_1: 0,
			cat_2: 0,
			cat_3: 0,
			cat_4: 0,
			url_site: undefined,
			facebook: '',
			twitter: '',
			instagram: '',
			geofootprint: '',
			priorite: '',
			onboarding: '',
			duplication_1: 0,
			duplication_2: 0,
			duplication_3: 0,
			duplication_4: 0,
			duplication_5: 0,
			id_mere: undefined,
			groupe_id: undefined,
		},
		synonym: {
			nom: '',
			synonym_1: '',
			synonym_2: '',
			synonym_3: '',
			synonym_4: '',
			synonym_5: '',
			output_name: '',
		},
		'1400x1400': {},
		'1920x1080': {},
		'1024x768': {},
		'112x32': {},
		streams: [],
	});
	const radiosList = useSelector(selectRadios);
	const categories = useSelector(selectCategories);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const successMessage = useSelector(selectRadiosMessage);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);
	useEffect(() => {
		if (token) {
			axios
				.get(`${REACT_APP_API_URL}/groups`, {
					headers: { 'x-auth-token': token },
				})
				.then((res) => {
					setRadioGroup(res.data.groups);
				})
				.catch(function (error) {
					navigate('/');
				});
		}
	}, [token, navigate]);
	useEffect(() => {
		if (token) {
			dispatch(getCategories(token, 'radios'));
			dispatch(getRadios(token));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate]);
	useEffect(() => {
		if (radiosList) {
			setRadios(radiosList.radios);
		}
	}, [dispatch, radiosList]);
	useEffect(() => {
		if (radios?.length > 0) {
			setSortedRadios(
				radios
					.map((radio) => {
						return { nom: radio?.nom, rpID: radio?.rpID };
					})
					.sort((a, b) => a?.nom.localeCompare(b?.nom))
			);
		}
	}, [radios]);
	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}
	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			radio: {
				...prevInputs.radio,
				[name]: name.includes('cat_') ? Number(value) : value,
			},
		}));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const updateFormParamsInput = (name, value, objectKey) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			[objectKey]: {
				...prevInputs[objectKey],
				[name]: name.includes('duplication_') ? Number(value) : value,
			},
		}));
	};

	const handleChangeFormParamsInputs = (event, objectKey) => {
		updateFormParamsInput(event.target.name, event.target.value, objectKey);
	};
	const handleChangeFormNumberInputs = (event, objectKey) => {
		updateFormInput(event.target.name, Number(event.target.value), objectKey);
	};
	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		dispatch(createRadio(token, formInputs));
		setIsModalOpen(true);
	};
	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
		dispatch(setErrorServerMessage(''));
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/Radios" className="fs-5 " onClick={eraseErrorMessage}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div className="tableWrapper">
			{type === 3 ? (
				<InsideMenu
					homeLink={'/radios_logo_only'}
					title={'Gestion des Radios Logo only'}
					links={[{ name: "Ajout d'une radio logo only", path: '/radios_logo_only/add' }]}
				/>
			) : (
				<InsideMenu
					homeLink={'/radios'}
					title={'Gestion des Radios'}
					links={[{ name: 'Gestion des radios', path: '/radios' }]}
				/>
			)}
			<div className="RadioManager bg-white rounded-4 p-5 mx-4">
				<form onSubmit={submitForm}>
					<RpidInput formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
					<RadioTypeInputs
						type={type}
						formInputs={formInputs}
						handleChangeFormInputs={handleChangeFormInputs}
					/>
					<GeneralsInfosInputs
						type={type}
						formInputs={formInputs}
						handleChangeFormInputs={handleChangeFormInputs}
					/>
					<ImgInputs
						type={type}
						formInputs={formInputs}
						updateFormInput={updateFormInput}
						setFormInputs={setFormInputs}
						isCreation={isCreation}
					/>
					{type === 3 ? null : (
						<>
							<CategoriesInputs
								formInputs={formInputs}
								handleChangeFormInputs={handleChangeFormInputs}
								categories={categories}
							/>
							<UrlInputs formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
							<StreamsInputs
								formInputs={formInputs}
								setFormInputs={setFormInputs}
								newStream={newStream}
								setNewStream={setNewStream}
							/>
							<SocialInputs formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
							<Geofootprint
								formInputs={formInputs}
								setFormInputs={setFormInputs}
								newGeofootprint={newGeofootprint}
								setNewGeofootprint={setNewGeofootprint}
								shape={shape}
								setShape={setShape}
								isRadio={true}
							/>
							<div>
								<div className="d-flex justify-content-start mt-4">
									<h5 className="fw-bold">Paramètres:</h5>
								</div>
								<OnboardingPriorityInputs
									handleChangeFormInputs={handleChangeFormParamsInputs}
									formInputs={formInputs}
								/>
								<DuplicationsInputs
									sortedRadios={sortedRadios}
									handleChangeFormInputs={handleChangeFormNumberInputs}
									formInputs={formInputs}
								/>
								<MotherAndGroupIDInputs
									sortedRadios={sortedRadios}
									handleChangeFormInputs={handleChangeFormNumberInputs}
									formInputs={formInputs}
									radioGroup={radioGroup}
								/>
								<div className="d-flex justify-content-start mt-3">
									<h5 className="fw-bold">Paramètres vocaux:</h5>
								</div>
								<CommandAndOutputInputs
									handleChangeFormInputs={handleChangeFormParamsInputs}
									formInputs={formInputs}
								/>
								<VocalSynForm
									handleChangeFormInputs={handleChangeFormParamsInputs}
									formInputs={formInputs}
								/>
							</div>
						</>
					)}
					<div className="d-flex mt-5">
						<input className="formSubmitButton" type="submit" value="Enregistrer" />
					</div>
				</form>
			</div>
			<div>
				<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
					{successMessage ? (
						<div className="text-success">
							Votre radio a bien été créée.
							<br />
							<Link
								to={type === 3 ? '/radios_logo_only' : '/radios'}
								className="fs-5 "
								onClick={eraseErrorMessage}
							>
								Retour à la page des radios
							</Link>
						</div>
					) : errorMessage ? (
						<div className="mt-5">
							<div className="text-danger">{errorMessage}</div>
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : errorMessage400.length > 0 ? (
						<div>
							<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
							{errorMessage400.map((message, index) => (
								<div key={index} className="my-2">
									<div className="text-danger"> - {message.msg}</div>
								</div>
							))}
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : null}
				</Modal>
			</div>
			<GoToTop />
		</div>
	);
}

export default RadioCreate;
