import React, { useRef } from 'react';

function AudioPlayer({ radioUrl, audioStopped }) {
	const audioRef = useRef(null);
	const audio = audioRef.current;
	if (audio && audioStopped) {
		audio.pause();
	}
	return (
		<div className="my-3">
			<audio controls ref={audioRef}>
				<source src={radioUrl} type="audio/mpeg" />
			</audio>
		</div>
	);
}

export default AudioPlayer;
