import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom';
import {
	selectRadio,
	selectRadios,
	selectToken,
	selectRadiosErrorMessage,
	selectRadiosMessage,
	selectCategories,
	selectRadiosErrorMessage400,
	selectRadiosServerErrorMessage,
} from '../../../selectors';
import { getRadio, getRadios, updateRadio } from '../../../services/Radios';
import { setErrorMessage, setErrorMessage400, setErrorServerMessage } from '../../../features/radiosSlice';
import { getCategories } from '../../../services/Categories';
import InsideMenu from '../../../components/InsideMenu';
import Geofootprint from '../../../components/Geofootprint';
import Spinner from '../../../components/Spinner';
import RadioTypeInputs from '../children/RadioTypeInputs';
import GeneralsInfosInputs from '../children/GeneralsInfosInputs';
import ImgInputs from '../children/ImgInputs';
import UrlInputs from '../children/UrlInputs';
import SocialInputs from '../children/SocialInputs';
import StreamsInputs from '../../../components/StreamsInputs';
import CategoriesInputs from '../children/CategoriesInputs';
import OnboardingPriorityInputs from '../children/OnboardingPriorityInputs';
import DuplicationsInputs from '../children/DuplicationsInputs';
import MotherAndGroupIDInputs from '../children/MotherAndGroupIDInputs';
import CommandAndOutputInputs from '../children/CommandAndOutputInputs';
import VocalSynForm from '../children/VocalSynForm';
import GoToTop from '../../../components/GoToTop';
import { modalStyles } from '../../../utils/const';
import { deleteWhiteSpacesInObject } from '../../../utils/functions';

const { REACT_APP_API_URL } = process.env;

function RadioUpdate({ type, isAdmin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams();
	const [currentRadio, setCurrentRadio] = useState({});
	const [isLoaded, setLoaded] = useState(false);
	const [currentRadioStreams, setCurrentRadioStreams] = useState([]);
	const [isPending, setIsPending] = useState(false);
	const [currentRadioLogos, setCurrentRadioLogos] = useState();
	const [newStream, setNewStream] = useState({ defaut: 'N', url: '', mime: '', bitrate: '' });
	const [newGeofootprint, setNewGeofootprint] = useState('');
	const [shape, setShape] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [radioSynonym, setRadioSynonym] = useState([]);
	const [radioGroup, setRadioGroup] = useState([]);
	const [sameTypeRadios, setSameTypeRadios] = useState([]);
	const [sameGroupeRadios, setSameGroupeRadios] = useState([]);
	const [radios, setRadios] = useState([]);
	const [formInputs, setFormInputs] = useState(
		isAdmin
			? {
					radio: {
						type: '',
						nom: '',
						nom_court: '',
						nom_moyen: '',
						description_courte: '',
						description: '',
						cat_1: 0,
						cat_2: 0,
						cat_3: 0,
						cat_4: 0,
						url_site: undefined,
						facebook: '',
						twitter: '',
						instagram: '',
						geofootprint: '',
						priorite: '',
						onboarding: '',
						duplication_1: 0,
						duplication_2: 0,
						duplication_3: 0,
						duplication_4: 0,
						duplication_5: 0,
						id_mere: undefined,
						groupe_id: undefined,
					},
					synonym: {
						nom: '',
						synonym_1: '',
						synonym_2: '',
						synonym_3: '',
						synonym_4: '',
						synonym_5: '',
						output_name: '',
					},
					'1400x1400': {},
					'1920x1080': {},
					'1024x768': {},
					'112x32': {},
					streams: [],
			  }
			: {
					radio: {
						type: '',
						nom: '',
						nom_court: '',
						nom_moyen: '',
						description_courte: '',
						description: '',
						cat_1: 0,
						cat_2: 0,
						cat_3: 0,
						cat_4: 0,
						url_site: undefined,
						facebook: '',
						twitter: '',
						instagram: '',
						geofootprint: '',
					},
					'1400x1400': {},
					'1920x1080': {},
					'1024x768': {},
					'112x32': {},
					streams: [],
			  }
	);
	const radiosList = useSelector(selectRadios);
	const radioData = useSelector(selectRadio);
	const categories = useSelector(selectCategories);
	const token = useSelector(selectToken) || sessionStorage.getItem('token');
	const errorMessage = useSelector(selectRadiosErrorMessage);
	const successMessage = useSelector(selectRadiosMessage);
	const errorMessage400 = useSelector(selectRadiosErrorMessage400);
	const serverErrorMessage = useSelector(selectRadiosServerErrorMessage);

	useEffect(() => {
		if (token) {
			axios
				.get(`${REACT_APP_API_URL}/groups`, {
					headers: { 'x-auth-token': token },
				})
				.then((res) => {
					setRadioGroup(res.data.groups);
				})
				.catch(function (error) {
					navigate('/');
				});
		}
	}, [token, navigate]);

	useEffect(() => {
		if (radioData) {
			setCurrentRadio(radioData.data);
			setCurrentRadioStreams(radioData.streams);
			setCurrentRadioLogos(radioData.logo);
			setIsPending(radioData.pending);
			setRadioSynonym(radioData.synonym);
			setLoaded(true);
		}
	}, [params, radioData]);

	useEffect(() => {
		if (currentRadio) {
			setFormInputs(
				isAdmin
					? {
							radio: {
								rpid: params.rpID,
								type: currentRadio.type,
								nom: currentRadio.nom,
								nom_court: currentRadio.nom_court,
								nom_moyen: currentRadio.nom_moyen,
								description_courte: currentRadio.description_courte,
								description: currentRadio.description,
								cat_1: currentRadio.cat_1,
								cat_2: currentRadio.cat_2,
								cat_3: currentRadio.cat_3,
								cat_4: currentRadio.cat_4,
								url_site: currentRadio.url_site,
								facebook: currentRadio.facebook,
								twitter: currentRadio.twitter,
								instagram: currentRadio.instagram,
								geofootprint: currentRadio.geofootprint,
								priorite: currentRadio?.priorite,
								onboarding: currentRadio?.onboarding?.toString(),
								duplication_1: currentRadio?.duplication_1,
								duplication_2: currentRadio?.duplication_2,
								duplication_3: currentRadio?.duplication_3,
								duplication_4: currentRadio?.duplication_4,
								duplication_5: currentRadio?.duplication_5,
								id_mere: currentRadio?.id_mere ? currentRadio?.id_mere : undefined,
								groupe_id: currentRadio?.groupe_id ? currentRadio?.groupe_id : undefined,
							},
							synonym: {
								nom: radioSynonym?.nom,
								synonym_1: radioSynonym?.synonym_1 ? radioSynonym?.synonym_1 : '',
								synonym_2: radioSynonym?.synonym_2 ? radioSynonym?.synonym_2 : '',
								synonym_3: radioSynonym?.synonym_3 ? radioSynonym?.synonym_3 : '',
								synonym_4: radioSynonym?.synonym_4 ? radioSynonym?.synonym_4 : '',
								synonym_5: radioSynonym?.synonym_5 ? radioSynonym?.synonym_5 : '',
								output_name: radioSynonym?.output_name,
							},
							streams: currentRadioStreams,
					  }
					: {
							radio: {
								rpid: params.rpID,
								type: currentRadio.type,
								nom: currentRadio.nom,
								nom_court: currentRadio.nom_court,
								nom_moyen: currentRadio.nom_moyen,
								description_courte: currentRadio.description_courte,
								description: currentRadio.description,
								cat_1: currentRadio.cat_1,
								cat_2: currentRadio.cat_2,
								cat_3: currentRadio.cat_3,
								cat_4: currentRadio.cat_4,
								url_site: currentRadio.url_site,
								facebook: currentRadio.facebook,
								twitter: currentRadio.twitter,
								instagram: currentRadio.instagram,
								geofootprint: currentRadio.geofootprint,
							},

							streams: currentRadioStreams,
					  }
			);
		}
	}, [params, radioData, currentRadio, currentRadioStreams, currentRadioLogos, radioSynonym, isAdmin]);

	useEffect(() => {
		if (token) {
			dispatch(getRadio(token, params.rpID, type));
			dispatch(getRadios(token));
			dispatch(getCategories(token, 'radios'));
		} else {
			navigate('/');
		}
	}, [dispatch, token, navigate, params, type]);

	useEffect(() => {
		if (currentRadio) {
			setShape(currentRadio.geofootprint);
		}
	}, [currentRadio]);
	useEffect(() => {
		if (radiosList) {
			setRadios(radiosList.radios);
		}
	}, [dispatch, radiosList]);

	useEffect(() => {
		if (radios?.length > 0) {
			setSameTypeRadios(
				radios
					.filter((radio) => radio?.type === currentRadio?.type)
					.map((radio) => {
						return { nom: radio?.nom, rpID: radio?.rpID };
					})
					.sort((a, b) => a?.nom.localeCompare(b?.nom))
			);
			setSameGroupeRadios(
				radios
					.filter((radio) => radio?.groupe_id === currentRadio?.groupe_id)
					.map((radio) => {
						return { nom: radio?.nom, rpID: radio?.rpID };
					})
					.sort((a, b) => a?.nom.localeCompare(b?.nom))
			);
		}
	}, [radios, currentRadio]);
	Modal.setAppElement('body');

	function closeModal() {
		setIsModalOpen(false);
		navigate('/radios');
	}

	const updateFormInput = (name, value) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			radio: {
				...prevInputs.radio,
				[name]: name.includes('cat_') ? Number(value) : value,
			},
		}));
	};

	const handleChangeFormInputs = (event) => {
		updateFormInput(event.target.name, event.target.value);
	};
	const updateFormParamsInput = (name, value, objectKey) => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			[objectKey]: {
				...prevInputs[objectKey],
				[name]: name.includes('duplication_') ? Number(value) : value,
			},
		}));
	};

	const handleChangeFormParamsInputs = (event, objectKey) => {
		updateFormParamsInput(event.target.name, event.target.value, objectKey);
	};
	const handleChangeFormNumberInputs = (event, objectKey) => {
		updateFormInput(event.target.name, Number(event.target.value), objectKey);
	};
	const submitForm = (event) => {
		event.preventDefault();
		deleteWhiteSpacesInObject(formInputs);
		if (type === 3) {
			formInputs.radio.type = 3;
		}
		dispatch(updateRadio(token, params.rpID, formInputs));
		setIsModalOpen(true);
	};

	const eraseErrorMessage = () => {
		dispatch(setErrorMessage(''));
		dispatch(setErrorMessage400([]));
		dispatch(setErrorServerMessage(''));
	};

	if (serverErrorMessage) {
		return (
			<div className="mt-5">
				<div className="fs-4 text-danger">{serverErrorMessage}</div>
				<Link to="/Radios" className="fs-5 " onClick={eraseErrorMessage}>
					Retour
				</Link>
			</div>
		);
	}
	return (
		<div>
			{type === 3 ? (
				<InsideMenu
					homeLink={'/radios_logo_only'}
					title={'Gestion des Radios Logo only'}
					links={[isAdmin ? { name: "Ajout d'une radio logo only", path: '/radios_logo_only/add' } : null]}
				/>
			) : (
				<InsideMenu
					homeLink={'/radios'}
					title={'Gestion des Radios'}
					links={[
						{ name: 'Gestion des radios', path: '/radios' },
						isAdmin ? { name: "Ajout d'une radio", path: '/radios/add' } : null,
						{ name: 'Podcasts', path: `/radios/${params.rpID}/podcasts` },
						{ name: 'Programmes', path: `/radios/${params.rpID}/programs` },
						{ name: 'Décrochages', path: `/radios/${params.rpID}/locals` },
						{ name: 'Fréquences', path: `/radios/${params.rpID}/frequencies/edit` },
					]}
				/>
			)}
			{isPending ? (
				<div className="text-danger py-4">
					Une modification est en attente de validation par l'équipe Radioplayer. <br />
					Si vous effectuez de nouvelles modifications, elles remplaceront les précédentes.
				</div>
			) : null}
			{isLoaded ? (
				<div className="RadioManager bg-white rounded-4 p-5 mx-4">
					<form onSubmit={submitForm}>
						<RadioTypeInputs
							type={type}
							formInputs={formInputs}
							handleChangeFormInputs={handleChangeFormInputs}
						/>
						<GeneralsInfosInputs
							type={type}
							formInputs={formInputs}
							handleChangeFormInputs={handleChangeFormInputs}
						/>
						<ImgInputs type={type} currentRadioLogos={currentRadioLogos} setFormInputs={setFormInputs} />
						{type === 3 ? null : (
							<>
								<CategoriesInputs
									formInputs={formInputs}
									handleChangeFormInputs={handleChangeFormInputs}
									categories={categories}
								/>

								<UrlInputs formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
								<StreamsInputs
									formInputs={formInputs}
									setFormInputs={setFormInputs}
									newStream={newStream}
									setNewStream={setNewStream}
								/>

								<SocialInputs formInputs={formInputs} handleChangeFormInputs={handleChangeFormInputs} />
								<Geofootprint
									formInputs={formInputs}
									setFormInputs={setFormInputs}
									newGeofootprint={newGeofootprint}
									setNewGeofootprint={setNewGeofootprint}
									shape={shape}
									setShape={setShape}
									isRadio={true}
								/>
								{isAdmin ? (
									<div>
										<div className="d-flex justify-content-start mt-4">
											<h5 className="fw-bold">Paramètres:</h5>
										</div>
										<OnboardingPriorityInputs
											handleChangeFormInputs={handleChangeFormParamsInputs}
											formInputs={formInputs}
										/>
										<DuplicationsInputs
											sortedRadios={sameTypeRadios}
											handleChangeFormInputs={handleChangeFormNumberInputs}
											formInputs={formInputs}
										/>
										<MotherAndGroupIDInputs
											sortedRadios={sameGroupeRadios}
											handleChangeFormInputs={handleChangeFormNumberInputs}
											formInputs={formInputs}
											radioGroup={radioGroup}
										/>
										<div className="d-flex justify-content-start mt-3">
											<h5 className="fw-bold">Paramètres vocaux:</h5>
										</div>
										<CommandAndOutputInputs
											handleChangeFormInputs={handleChangeFormParamsInputs}
											formInputs={formInputs}
										/>
										<VocalSynForm
											handleChangeFormInputs={handleChangeFormParamsInputs}
											formInputs={formInputs}
										/>
									</div>
								) : null}
							</>
						)}
						<div className="row">
							<div className="d-flex mt-5">
								<input className="formSubmitButton" type="submit" value="Enregistrer" />
							</div>
						</div>
					</form>
				</div>
			) : (
				<Spinner />
			)}
			<div>
				<Modal isOpen={isModalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="modal">
					{successMessage ? (
						!isAdmin ? (
							<div className="text-success">
								Votre demande de modification a bien été envoyée.
								<br /> Elle sera examinée par notre équipe dans un délai de 24h.
								<br />
								<Link
									to={type === 3 ? '/radios_logo_only' : '/radios'}
									className="fs-5 "
									onClick={eraseErrorMessage}
								>
									Retour à la page des radios
								</Link>
							</div>
						) : (
							<div className="text-success">
								Les modifications ont été enregistrées. <br />
								<Link
									to={type === 3 ? '/radios_logo_only' : '/radios'}
									className="fs-5 "
									onClick={eraseErrorMessage}
								>
									Retour à la page des radios
								</Link>
							</div>
						)
					) : errorMessage ? (
						<div className="mt-5">
							<div className="text-danger">{errorMessage}</div>
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : errorMessage400.length > 0 ? (
						<div>
							<div>Les erreurs suivantes sont apparues dans les données de votre formulaire:</div>
							{errorMessage400.map((message, index) => (
								<div key={index} className="my-2">
									<div className="text-danger"> - {message.msg}</div>
								</div>
							))}
							<div className="d-flex justify-content-center">
								<button
									className="btn btn-secondary w-30"
									onClick={() => {
										setIsModalOpen(false);
										eraseErrorMessage();
									}}
								>
									Retour
								</button>
							</div>
						</div>
					) : null}
				</Modal>
			</div>
			<GoToTop />
		</div>
	);
}

export default RadioUpdate;
