import React from 'react';
import { MdDeleteForever } from 'react-icons/md';

function StreamsInputs({ formInputs, setFormInputs, newStream, setNewStream }) {
	const findStreamIndexToModify = (id) => {
		const indexOfStreamToModify = formInputs.streams.findIndex((a) => a.id === id);
		return indexOfStreamToModify;
	};
	const updateStreams = (name, value, id) => {
		const updatedStreams = formInputs.streams.map((stream, index) =>
			index === findStreamIndexToModify(id)
				? {
						...stream,
						[name]: name === 'defaut' ? (stream.defaut === 'O' ? 'N' : 'O') : value,
					}
				: stream
		);
		setFormInputs({
			...formInputs,
			streams: updatedStreams,
		});
	};

	const addStream = () => {
		setFormInputs((prevInputs) => ({
			...prevInputs,
			streams: [...prevInputs.streams, newStream],
		}));
		const id = formInputs.streams.length;
		setNewStream({ defaut: 'N', url: '', mime: '', bitrate: '', id: id });
		setTimeout(() => {
			document.getElementById(`url_${id}`).focus();
		}, 100);
	};
	const deleteStream = (index) => {
		const streamArray = [...formInputs.streams];
		streamArray.splice(index, 1);
		setFormInputs({
			...formInputs,
			streams: streamArray,
		});
	};

	return (
		<>
			{formInputs.streams.map((stream, index) => (
				<div className="row" key={index}>
					<div className="mb-3 col-6">
						<label className="form-label d-flex justify-content-start">
							<b>Stream {index + 1}</b>
							{index >= 9 ? null : '\u00A0'}&nbsp; URL - par défaut
							<input
								key={`check_${index}`}
								id={`check_${index}`}
								type="checkbox"
								name="defaut"
								className="ms-1"
								checked={stream.defaut === 'O'}
								onChange={(e) => updateStreams(e.target.name, e.target.value, stream.id)}
							/>
						</label>
						<input
							className="form-control"
							type="text"
							name="url"
							key={`url_${index}`}
							id={`url_${index}`}
							value={stream.url}
							onChange={(e) => updateStreams(e.target.name, e.target.value.trim(), stream.id)}
						/>
					</div>
					<div className="mb-3 col-3">
						<label className="form-label d-flex justify-content-start">Mime Value</label>
						<select
							className="form-select"
							name="mime"
							key={`mime_${index}`}
							id={`mime_${index}`}
							value={stream.mime}
							onChange={(e) => updateStreams(e.target.name, e.target.value, stream.id)}
						>
							<option value=""></option>
							<option value="audio/mpeg">audio/mpeg</option>
							<option value="audio/aac">audio/aac</option>
							<option value="audio/x-mpegurl">audio/x-mpegurl</option>
						</select>
					</div>
					<div className="mb-3 col-3 d-flex">
						<div className="col-11">
							<label className="form-label d-flex justify-content-start">Bitrate </label>
							<input
								className="form-control"
								type="text"
								name="bitrate"
								key={`bitrate_${index}`}
								id={`bitrate_${index}`}
								value={stream.bitrate}
								onChange={(e) => updateStreams(e.target.name, e.target.value, stream.id)}
							/>
						</div>
						<div className="d-flex align-items-center ms-4 mt-4">
							<div className="streamDeleteButton" onClick={() => deleteStream(index)}>
								<MdDeleteForever size={20} />
							</div>
						</div>
					</div>
				</div>
			))}

			<div className="justify-content-center my-3">
				<div className="btn btn-outline-secondary" onClick={addStream}>
					Ajouter Stream
				</div>
			</div>
		</>
	);
}

export default StreamsInputs;
